<div
  class="project"
  [class.image-left]="imageOnLeft"
  [class.image-right]="!imageOnLeft"
  (mouseover)="zoomIn()"
  (mouseleave)="zoomOut()"
>
  <div
    class="project-text"
    [ngClass]="{ 'text-left': imageOnLeft, 'text-right': !imageOnLeft }"
  >
    <h3>{{ title }}</h3>
    <p class="technologies">
      <ng-container *ngFor="let tech of technologies; let last = last">
        {{ tech }}<ng-container *ngIf="!last"> | </ng-container>
      </ng-container>
    </p>
    <p>{{ description }}</p>
    <div class="project-buttons">
      <!-- Live Test Button -->
      <a
        target="_blank"
        class="btn-liveTest"
        [href]="liveTestLink" 
      >
        {{ "project.live_test" | translate }}
      </a>

      <!-- GitHub Button -->
      <a
        target="_blank"
        class="btn-github"
        [href]="githubLink" 
      >
        {{ "project.github" | translate }}
      </a>
    </div>
  </div>
  <div class="project-image">
    <img [src]="imageUrl" alt="{{ title }}" />
  </div>
</div>
